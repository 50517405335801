import React from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import MeritHeader from '../../../components/about/MeritHeader';
import withTranslations from '../../../components/hoc/withTranslations';

const Success = () => (
  <Layout noFooter>
    <Seo title='¡Registrado!' keywords={[`success`, `application`, `react`]} />
    <div className='content max-w-5xl mx-auto px-4'>
      <MeritHeader>¡Gracias!</MeritHeader>
      <p>
        Ya estás inscrito en el taller Webapps modernas con React. En los próximos días te enviaremos por correo
        electrónico el enlace de las sesiones de Zoom. Mantente alerta para próximos cursos y eventos.
      </p>

      <a
        target='_blank'
        href='https://calendar.google.com/event?action=TEMPLATE&amp;tmeid=N2NuMWxjNW91NGFzczRsZmVuNGppZ2RtZ3YgYWxleHJ2NDI5OUBt&amp;tmsrc=alexrv4299%40gmail.com'
        className='global-primary-button bg-blue-600 text-white flex-none inline-block text-sm mb-8 py-2 px-3'
      >
        Agregar a Google Calendar
      </a>

      <p>
        Si tienes una duda,{' '}
        <a href='http://m.me/leavetheordinary'>
          <b>
            <u>contáctanos ahora</u>
          </b>
        </a>
        .
      </p>
    </div>
  </Layout>
);

export default withTranslations()(Success);
